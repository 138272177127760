<template>
  <v-main>
    <v-card flat>
      <v-toolbar flat color="white">
        <v-row>
          <v-col cols="12" md="6">
            <v-toolbar-title>
              Заказ № {{ orderId }} от {{ order.created | dateformat }} ({{ order.order_type }})
            </v-toolbar-title>
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <v-menu open-on-hover top offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" class="me-2">
                  Документы
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in sortInvoiceButtons(invoiceButtons)"
                  :key="index"
                >
                  <a :href="item.link" target="_blank">
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </a>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn color="primary" @click="saveOrderSettings()">
              Сохранить изменения
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <b>Номер заказа:</b>
            {{ order.id }}
          </v-list-item-content>
          <v-list-item-content>
            <b>Компания:</b>
            <router-link :to="{ name: 'CompanyView', params: { id: order.company_id }}">
            {{ order.company }}
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Номер заказа в 1С:</b>
            {{ order.number_1c }}
          </v-list-item-content>
          <v-list-item-content>
            <b>Создана реализация в 1С:</b>
            {{ trueFalse(order.has_1c_clientorder) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Дата создания:</b>
            {{ order.created | dateformat }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="order.completed">
          <v-list-item-content>
            <b>Дата выполнения:</b>
            {{ order.completed | dateformat }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Со склада</b>
            {{ order.stock_from_display }}
          </v-list-item-content>
          <v-list-item-content>
            <b>На склад</b>
            {{ order.stock_to_display }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Курс:</b>
            {{ order.actual_course_rub_usd }}₽ за $1
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <b>Статус:</b>
            {{ getStatusText(order.status, statusOptions) }}
          </v-list-item-content>
          <v-list-item-content>
            <div v-if="getFilteredStatusOptions().length > 0">
              <v-select
                v-model="order.status"
                :items="getFilteredStatusOptions()"
                label="Статус"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!order.is_marketplace">
          <v-list-item-content>
            <b>Статус оплаты:</b>
            {{ getStatusText(order.payment_status, paymentOptions) }}
          </v-list-item-content>
          <v-list-item-content>
            <v-select
              v-if="getPaymentChangePermission"
              v-model="order.payment_status"
              :items="paymentOptions"
              label="Статус оплаты"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-title>Список товаров</v-card-title>
      <v-data-table
        :headers="headersItems"
        :items="order.items"
        item-key="id"
        dense
        flat
        disable-pagination
        hide-default-footer
      >
        <template #[`item.quantity`]="{ item }">
          <v-text-field
            v-model="item.quantity"
            type="number"
            min="1"
            @input="updateQuantity(item)"
            :rules="[requiredRule]"
            :disabled="order.payment_status !== 'not_paid'"
          ></v-text-field>
        </template>
        <template #[`item.amount_usd`]="{ item }">
          {{
            item.amount_usd | currency(`USD`)
          }}
        </template>
        <template #[`item.price_usd`]="{ item }">
          {{
            item.price_usd | currency(`USD`)
          }}
        </template>
        <template #[`item.note`]="{ item }">
          <v-tooltip
            v-if="item.note"
            bottom
            max-width="400"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="blue"
                v-on="on"
              >
                mdi-comment
              </v-icon>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
          <v-icon
            v-else
            color="gray"
          >
            mdi-comment
          </v-icon>
        </template>
        <template #[`body.append`]="{ headers }">
          <tr v-if="$vuetify.breakpoint.name == 'xs'">
            <th>
              <v-row>
                <v-col cols="6">
                  Итого
                </v-col>
                <v-col
                  class="text-right"
                  cols="6"
                >
                  {{
                    order.amount | currency(`USD`)
                  }}
                </v-col>
              </v-row>
            </th>
          </tr>
          <tr v-else>
            <th>Итого</th>
            <th :colspan="headers.length - 5"/>
            <th>{{ order.quantity }}</th>
            <th/>
            <th>{{ order.amount | currency(`USD`) }}</th>
            <th/>
          </tr>
        </template>
      </v-data-table>
      <v-textarea v-model="order.note" label="Примечание "></v-textarea>
    </v-card>
  </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import stocks from "@/api/stocks";
import router from "@/router";

export default {
  name: "OrderAdminView",
  data: () => ({
    order: {},
    statusOptions: [
      {text: "Ожидает заполнения", value: "pending"},
      {text: "Новый заказ", value: "new"},
      {text: "Готово к отгрузке", value: "ready_for_shipment"},
      {text: "Передано в ТК", value: "transferred_to_tc"},
      {text: "Доставка со склада", value: "delivery"},
      {text: "Частично доставлено", value: "semi_delivered"},
      {text: "Доставлено", value: "delivered"},
      {text: "Выполнено", value: "completed"},
      {text: "Архив", value: "archived"},
      {text: "В процессе заполнения", value: "fbs_processing"},
      {text: "Доставка с производства", value: "delivery_from_factory"},
      {text: "Производство", value: "factoring"},
    ],
    paymentOptions: [
      {text: "Оплачено 100%", value: "paid_100"},
      {text: "Оплачено 70%", value: "paid_70"},
      {text: "Оплачено 50%", value: "paid_50"},
      {text: "Не оплачено", value: "not_paid"},
    ],
    requiredRule: [(v) => !!v || 'Обязательное поле'],
  }),
  computed: {
    ...mapGetters("auth", ["getUsername", "getUserGroup", "isFranchiseeGroup", "isWholeSaleGroup", "isWholesaleHunter",
      "isWholesaleFarmer", "isWholesalePartner", "isPurchasingManager", "isWholesaleROP", "isDirectorOfFranchising", "isSuperUser", "isAdmin", "isStorekeeper"]),
    sortedInvoiceButtons() {
      return this.sortInvoiceButtons(this.invoiceButtons);
    },
    invoiceButtons() {
      return [
        { text: "Счёт для франчайзи 100%", link: this.getInvoiceFranchisee(100) },
        { text: "Счёт для франчайзи 70%", link: this.getInvoiceFranchisee(70) },
        { text: "Счёт для франчайзи 50%", link: this.getInvoiceFranchisee(50) },
        { text: "Счёт для франчайзи 30%", link: this.getInvoiceFranchisee(30) },
        { text: "Заявка для завода", link: this.getInvoiceFactory() },
        { text: "Ведомость для склада", link: this.getInvoiceForStock() },
      ];
    },
    headersItems() {
      let headers = [
        {text: "Артикул", value: "product.vendor_code", isMarketplaceManager: false},
        {text: "Наименование", value: "product.title"},
        {text: "Штрих-код", value: "product.bar_code", isMarketplaceManager: true},
        {text: "Артикул", value: "product.vendor_code", isMarketplaceManager: true},
        {text: "Количество", value: "quantity"},
        {text: "Стоимость", value: "price_usd"},
        {text: "Стоимость позиции", value: "amount_usd"},
        {text: "Комментарий", value: "note"},
      ];
      return this.order.is_marketplace
        ? headers.filter((el) => el.isMarketplaceManager !== false)
        : headers.filter((el) => el.isMarketplaceManager !== true);
    },
    headersPayments() {
      return [
        {text: "Сумма", value: "amount"},
        {text: "Дата", value: "created"},
      ];
    },
    orderId() {
      return this.$route.params.id;
    },
    subTitle() {
      if (this.order.is_marketplace) {
        return this.order.type + " " + this.order.stock_to // Отображение названия склада назначения и типа заявки
      } else {
        if (this.order.from_china) {
          return "(с производства)";
        } else {
          return "(со склада)";
        }
      }
    },
    getPaymentChangePermission() {
      return this.isDirectorOfFranchising || this.isPurchasingManager || this.isWholesaleROP || this.isSuperUser || this.isAdmin;
    },
  },
  methods: {
    ...mapActions(["fetchOrder"]),
    sortInvoiceButtons(buttons) {
      return this.isDirectorOfFranchising
        ? buttons.filter((el) => el.isDirectorOfFranchising !== false)
        : buttons.filter((el) => el.isDirectorOfFranchising !== true);
    },
    getInvoiceFranchisee(percent) {
      return `${this.$store.state.baseURL}/stocks/order/${this.orderId}/pdf/?percent=${percent}&token=${this.$store.state.auth.token}`;
    },
    getInvoiceFactory() {
      let rawURL = `${this.$store.state.baseURL}/stocks/order/${this.orderId}/xlsx`;
      return rawURL.replace('api/v1/', '');
    },
    getInvoiceForStock() {
      let rawURL = `${this.$store.state.baseURL}/stocks/order/${this.orderId}/xlsx_prepayment`;
      return rawURL.replace('api/v1/', '');
    },
    sortOrderStatusOptions(options) {
      options = this.isDirectorOfFranchising
        ? options.filter((el) => el.isDirectorOfFranchising !== false)
        : options.filter((el) => el.isDirectorOfFranchising !== true);
      return options;
    },
    getFilteredStatusOptions() {
      let filteredOptions = [];

      if (this.isSuperUser || this.isAdmin) {
        return this.statusOptions;
      }

      if (this.isPurchasingManager) {
        filteredOptions = this.statusOptions.filter(option =>
          ['new', 'factoring'].includes(option.value)
        );
      } else if (this.isStorekeeper) {
        const currentStatusOption = this.statusOptions.find(option => option.value === this.order.status);

        if (currentStatusOption) {
          filteredOptions.push(currentStatusOption);
        }

        if (this.order.type === 'FBS') {
          const transferredToTCOption = this.statusOptions.find(option => option.value === 'transferred_to_tc');
          if (transferredToTCOption) {
            filteredOptions.push(transferredToTCOption);
          }
        } else {
          const readyForShipmentOption = this.statusOptions.find(option => option.value === 'ready_for_shipment');
          if (readyForShipmentOption) {
            filteredOptions.push(readyForShipmentOption);
          }
        }
      } else if (this.userGroup === 'LOGISTICS_MANAGER') {
        filteredOptions = this.statusOptions.filter(option =>
          ['ready_for_shipment', 'delivery', 'delivered'].includes(option.value)
        );

        if (!this.order.is_marketplace) {
          const semiDeliveredOption = this.statusOptions.find(option => option.value === 'semi_delivered');
          if (semiDeliveredOption) {
            filteredOptions.push(semiDeliveredOption);
          }
        }

        if (this.order.from_china) {
          const deliveryFromFactoryOption = this.statusOptions.find(option => option.value === 'delivery_from_factory');
          if (deliveryFromFactoryOption) {
            filteredOptions.push(deliveryFromFactoryOption);
          }
        }
      }

      return filteredOptions;
    },
    trueFalse(value) {
      return value ? "Да ✅" : "Нет ❌";
    },
    getStatusText(statusValue, options) {
      const status = options.find(option => option.value === statusValue);
      return status ? status.text : '';
    },
    async saveOrderSettings() {
      try {
        const order = JSON.parse(JSON.stringify(this.order));
        order.company = this.order.company_id;
        order.stock_from = this.order.stock_from_id;
        order.stock_to = this.order.stock_to_id;
        order.items.forEach((item) => {
          item.product = item.product.id;
        });
        const response = await stocks.patchOrder(order);
        await router.push({name: 'OrderAdminList', params: {id: response.data.id}});
        location.reload();
        await this.$store.dispatch('alertSuccess', 'Заказ успешно отправлен', {root: true});
      } catch (error) {
        console.error('Ошибка при сохранении заказа:', error.response.data);
      }
    },


  },
  filters: {
    dateformat(value) {
      return new Date(value).toLocaleString("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
  created() {
    stocks
      .getAdminOrder(this.orderId)
      .then((response) => {
        this.order = response.data;
        this.itemsLoading = false;
      })
      .catch((error) => this.$store.dispatch("alertError", error));
  },
};
</script>

<style scoped>
.v-card {
  padding: 1rem;
}
</style>
